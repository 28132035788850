import React, { useRef, useState } from 'react';
import '../../../Styles/Emagrecer.css'
import Emagrecimento from "./../../../img/DietaTreino.png";
import { UserAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Emagresce() {
  const pixCodeRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const { adicionarTicket } = UserAuth();
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState('pix'); // Adicionado para corrigir o erro

  const Voltar = () => {
    navigate('/home')
  };

  const copyPixCode = () => {
    pixCodeRef.current.select();
    document.execCommand('copy');
    setIsCopied(true);
    adicionarTicket(1);
    // Reset isCopied after 3 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 30000);
  };

  const defaultPixCode = "00020101021126420014br.gov.bcb.pix0120jaltair744@gmail.com52040000530398654049.995802BR5920ALTAIR J L ITABORAHY6013VOLTA REDONDA62070503***63041BAB";

  return (
    <div className="emagresce-container">
      <div className="payment-card w-80 md:w-1/2">
        <h2 className="payment-card-title">Detalhes do Pagamento</h2>
        <img
          src={Emagrecimento}
          alt="emagrecer"
          className="payment-image mb-5 rounded-2xl md:w-3/5 md:mx-auto"
        />
        <div className="payment-card-content">
          <div className="payment-method-buttons">
            <button
              className={`payment-method-button ${paymentMethod === 'pix' ? 'active' : ''}`}
              onClick={() => setPaymentMethod('pix')}
            >
              PIX
            </button>
          
          </div>
          {paymentMethod === 'pix' && (
            <>
              <label htmlFor="pix-code">Código PIX Copia e Cola:</label>
              <input
                type="text"
                id="pix-code"
                className="pix-input md:w-3/5 md:mx-auto"
                ref={pixCodeRef}
                defaultValue={defaultPixCode}
              />
              <button
                className="copy-button md:w-3/5 md:mx-auto"
                onClick={copyPixCode}
              >
                Copiar Código PIX
              </button>
            </>
          )}
          {isCopied && (
  <div className="overlay success-message-container">
    <div className="success-message">
      Código copiado com sucesso!✅<br></br> <br></br>Após o pagamento, retorne à página e preencha suas informações novamente!
    </div>
  </div>
)}

        </div>
      </div>
      <button
        className="mt-5 text-white px-10 py-3 rounded-lg bg-green-900"
        onClick={Voltar}
      >
        Voltar
      </button>
    </div>
  );
}
