import React, { useState, useEffect } from "react";
import "./Modal.css";
import { Link } from "react-router-dom";
import Emagrecimento from "./../../img/Emagrecer.png";
import dietaTreino from "./../../img/MassaMuscular.png"
import Dieta3 from "./../../img/Artjejum1.png"
import definicaomassa from "./../../img/DietaTreino.png"
import definicaoM from '../../img/Definicao.png'

const Product = ({ imageSrc, altText, description, idName }) => (
  <div className="product shadow-md rounded-md mb-2 p-2 bg-gray-100 md:w-4/5 md:mx-auto">
    <img
      src={imageSrc}
      alt={altText}
      className="product-image mt-1 mx-auto shadow-md"
      loading="eager"
    />
    <p className=" md:w-4/5 mx-auto">{description}</p>
    <Link to={idName}>
    <button className="mb-1 bg-green-500" style={{ backgroundColor: '#66AB48' }}>
  COMPRAR
</button>

    </Link>
  </div>
);

const Modal = ({ isOpen, onClose }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // Função para pré-carregar as imagens
    const preloadImages = () => {

      const imageArray = [
        Emagrecimento,
        Emagrecimento,
        Emagrecimento,
        Emagrecimento,
      ];
      const imagePromises = imageArray.map((src) => {
        const image = new Image();
        image.src = src;
        return new Promise((resolve) => {
          image.onload = resolve;
        });
      });

      Promise.all(imagePromises)
        .then(() => {
          setImagesLoaded(true);
        })
        .catch((error) => {
          console.error("Erro ao carregar imagens:", error);
        });
    };

    // Pré-carrega as imagens apenas quando o modal é aberto
    if (isOpen && !imagesLoaded) {
      preloadImages();
    }
  }, [isOpen, imagesLoaded]);

  const modalClasses = isOpen ? "modal-overlay open" : "modal-overlay";
  const contentClasses = isOpen ? "modal-content open" : "modal-content";

  if (!isOpen) {
    return null;
  }

  return (
    <div className={modalClasses} onClick={onClose}>
      <div className={`${contentClasses} w-5/6 md:w-3/5`} onClick={(e) => e.stopPropagation()}>
        {imagesLoaded ? (

          <>

<button className="absolute px-3 py-2 bg-green-400 top-0 right-1 md:top-3 md:right-3" onClick={onClose} style={{ backgroundColor: '#66AB48' }}>
  X
</button>

            <Product
              imageSrc={Emagrecimento}
              altText="Produto 1"
              description="Isso mesmo! 🎉 O mais vendido e o mais querido de todos(a). 🌟 Inúmeras pessoas que compraram amaram esse super Treino personalizada. 💖"
              idName="/emagrecer"
            />


           

            <Product
              imageSrc={definicaomassa}
              altText="Produto 5"
              description="Sua Dieta + Treino o combo perfeito para potencializar seus resultados. 💪"
              idName="/definicaomassa"
            />

<Product
              imageSrc={definicaoM}
              altText="Produto 4"
              description="Sua Dieta totalmente focada em definição muscular. 💪"
              idName="/definicaom"
            />

            <Product
              imageSrc={Dieta3}
              altText="Produto 5"
              description="Sua Dieta totalmente personalizada focada em jejum intermitente 😊"
              idName="/definicao"
            />
               <Product
              imageSrc={dietaTreino}
              altText="Produto 3"
              description="Ganho de massa muscular, perfeito para quem quer aumentar a massa corporal 💪 "
              idName="/dietatreino"
            />
          </>
        ) : (
          <div className="loading-indicator">Carregando...</div>
        )}
      </div>
    </div>
  );
};

export default Modal;
