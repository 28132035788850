import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';


// Importe a imagem aqui (caso tenha uma imagem de logo)
import NutriImagem from '../../img/NUTRIINTELIGENTELOGO.png';


const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn } = UserAuth();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      navigate('/home');
    } catch (e) {
      if (e.message === "Firebase: Error (auth/user-not-found).") {
        setError("Email Incorreto")
      } else if (e.message === "Firebase: Error (auth/wrong-password).") {
        setError("Senha Incorreta")
      }
    }
  };



  return (
    <div className="max-w-[700px] h-full mx-auto ">
      <div className="text-center ">
        {/* eslint-disable-next-line */}
        <img src={NutriImagem} alt="Nutri Image" className="w-64 h-64 mx-auto" />
      </div>
      <form onSubmit={handleSubmit} className="bg-white rounded-lg h-full p-6">
        <div className="mb-3">
          <label className="block text-gray-700 font-medium">Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="border border-red-500 rounded-lg p-3 w-full"
            type="email"
            placeholder="Seu email"
            required
          />
        </div>
        <div className="mb-3">
          <label className="block text-gray-700 font-medium">Senha</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className="border border-red-500 rounded-lg p-3 w-full"
            type='password'
            placeholder="Sua senha"
            required
          />

        </div>
        {error && <p className="text-center text-red-600 mb-3">{error}</p>}
        <button style={{ backgroundColor: '#66AB48' }} className="text-white font-medium py-3 rounded-lg w-full hover:bg-red-600">
  ENTRAR
</button>


        <div className="mt-3 text-center">
          <p className='mb-3'>
            Ainda não possui uma conta?{' '}
          </p>
          <Link to="/signup">
          <button style={{ backgroundColor: '#66AB48' }} className="text-white font-medium py-3 rounded-lg w-full hover:bg-red-600">
  CRIAR CONTA
</button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Signin;
