import React, { useState, useEffect } from 'react';
import './ChatContainer.css';
import jsPDF from 'jspdf';
import { UserAuth } from '../../context/AuthContext';
import Modal from '../Modal/Modal';


function ChatContainer({ sendRequestToGPT, answer }) {
  const [goal, setGoal] = useState('');
  const [Restricao, setRestricao] = useState('');
  const[ Alimentosnao, setAlimentosnao ] = useState('') 
  const [Alimentos,setAlimentos] = useState('');
  const [Altura,setAltura] = useState('');
  const [Preferencia,setPreferencia] = useState('');
  const [genero, setGenero] = useState('');
  const [selectedFoods3, setSelectedFoods3] = useState([]);

  
  const availableFoodsFrutas = ['Braço', 'Ombro', 'Peitos', 'Costas', 'Perna', 'Glúteo', 'Panturrilha', 'Abdomem'];
  //

  const [error, setError] = useState('');
  const [recomend, setRecomend] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { retornaTicket, retirarTicket } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);


  function handleFoodSelection(selectedFoods, setSelectedFoods, food) {
    if (selectedFoods.includes(food)) {
      setSelectedFoods(selectedFoods.filter((item) => item !== food));
    } else if (selectedFoods.length < 5) {
      setSelectedFoods([...selectedFoods, food]);
    }
  }

  const openBuyModal = () => {
    setIsBuyModalOpen(true);
  };

  const closeBuyModal = () => {
    setIsBuyModalOpen(false);
  };

  useEffect(() => {
    retornaTicket()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
        } else {
          setUserTickets('Nenhum ticket disponível');
        }
      })
      .catch((error) => {
        setUserTickets('Erro ao obter os tickets');
      });
  }, [retornaTicket]);

  useEffect(() => {
    const messages = [
      'GERANDO SEU TREINO 💪',
      'FAZENDO NOSSO MELHOR🏆',
      'NÃO SAIA DA PAGINA⏳',
      'MONTANDO UM TREINO TOP 💪',
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      setLoadingMessage(messages[currentIndex]);
      currentIndex = (currentIndex + 1) % messages.length;
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSubmit = async () => {
    if (userTickets === 0) {
      openBuyModal();
      return;
    } else if ( !goal) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    setIsLoading(true);
    document.body.classList.add('scroll-lock');

    const data = `
    Faça uma Dieta com ênfase em ${goal} O meu género é ${genero}, o meu peso é ${Restricao}, e o treino deve ser no local: ${Preferencia}. Além disso, gostaria de incluir exercícios específicos para fortalecer os meus músculos favoritos, que são ${selectedFoods3}. minha altura é ${Altura}, e gosto dos alimentos: ${Alimentos} e nao gosto ${Alimentosnao} Por favor, seja breve na resposta.Quero apenas a dieta"   `;

  console.log(data);


    try {

      if (userTickets === 1 ) {

        await sendRequestToGPT(data);
      } 

      setGoal('');
      setSelectedFoods3([]);

      setError('');
      setRecomend('Aviso: Para salvar sua dieta clique em Baixar treino PDF');
      retirarTicket();
    } catch (err) {

      setError('Ocorreu um erro ao processar a solicitação.');
    } finally {
      setIsLoading(false);
      document.body.classList.remove('scroll-lock');
    }


  };

  const handleDownloadPDF = () => {
    if (!answer) {
      return;
    }

    const pdf = new jsPDF();


    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width - 8;
    const pageHeight = pdf.internal.pageSize.height - 1 * 8;

    const fontSize = 12;

    const splitText = pdf.splitTextToSize(answer, pageWidth);

    let yPosition = margin;

    for (let i = 0; i < splitText.length; i++) {

      if (yPosition + 5 > pageHeight) {
        pdf.addPage();
        yPosition = margin;
      }


      pdf.setFontSize(fontSize);
      pdf.text(margin, yPosition, splitText[i]);
      yPosition += margin - 2;
    }

    const fileName = `MeuPesoInteligente.pdf`;
    pdf.save(fileName);
  };

  return (
    <div className="chat-container w-4/5">
      <div className="chat-header">
        <h5 className='emagrecertt'>Preencha os campos a seguir</h5>
      </div>
      <div className="chat-input">
        <label htmlFor="goal">Qual seu objetivo no momento?</label>
        <select id="goal" value={goal} onChange={(e) => setGoal(e.target.value)}>
          <option value="">Selecione seu objetivo</option>
          <option value="Emagrecimento com facilidade"> Emagrecer</option>
          <option value="Ganhar massa muscular">Ganho de massa</option>
          <option value="Jejum Intermitente">Jejum Intermitente</option>
          <option value="Definição muscular">Definição Muscular</option>
   
        </select>
      </div>
      <div className="chat-input">
        <label htmlFor="Genero">Nos fale seu Gênero</label>
        <select id="genero" value={genero} onChange={(e) => setGenero(e.target.value)}>
          <option value="">Informe seu gênero</option>
          <option value="Masculino">Masculino</option>
          <option value="Feminino">Feminino</option>
        </select>
      </div>
      
      <div className="chat-input">
  <label htmlFor="Restricao">Qual é seu peso?</label>
  <input
    type="text"
    id="Restricao"
    value={Restricao}
    onChange={(e) => setRestricao(e.target.value)}
    placeholder="Digite seu peso"
  />
</div>
<div className="chat-input">
  <label htmlFor="Altura">Qual é sua altura?</label>
  <input
    type="text"
    id="Altura"
    value={Altura}
    onChange={(e) => setAltura(e.target.value)}
    placeholder="Digite sua altura (em metros)"
  />
</div>
<div className="chat-input">
  <label htmlFor="Alimentos">Quais alimentos voce gosta?</label>
  <input
    type="text"
    id="Alimentos"
    value={Alimentos}
    onChange={(e) => setAlimentos(e.target.value)}
    placeholder="Digite os alimentos preferidos"
  />
</div>
<div className="chat-input">
  <label htmlFor="Alimentosnao">Quais alimentos voce nao gosta?</label>
  <input
    type="text"
    id="Alimentosnao"
    value={Alimentosnao}
    onChange={(e) => setAlimentosnao(e.target.value)}
    placeholder="Digite os alimentos que nao gosta"
  />
</div>
      <div className="chat-input">
        <label htmlFor="Preferencia">Treino em casa ou academia?</label>
        <select id="Preferencia" value={Preferencia} onChange={(e) => setPreferencia(e.target.value)}>
          <option value="choose">Escolha uma</option>
          <option value="Nao quero treino ">Não quero treinar</option>
          <option value="Na Academia">Academia</option>
          <option value="Em casa,faça com peso caseiros">Em casa</option>
        </select>
      </div>
    
      <div className=" flex flex-col border rounded-lg font-bold mb-2 p-2">
        <label htmlFor="obs3">Qual parte do corpo você quer dar Preferência?</label>
        <br></br>
        <div className='grid grid-rows-2 grid-flow-col flex-wrap'>
          {availableFoodsFrutas.map((food) => (
            <label key={food} className=' text-sm md:text-lg'>
              <input className='w-5 h-5 mr-1'
                type="checkbox"
                value={food}
                checked={selectedFoods3.includes(food)}
                onChange={() => handleFoodSelection(selectedFoods3, setSelectedFoods3, food)}
                disabled={selectedFoods3.length === 5 && !selectedFoods3.includes(food)}
              />
              {food}
              </label>
          ))}
        </div>
      </div>

      {userTickets === 0 ? (
  <div>
   
    <button  className="send-button my-3" onClick={openBuyModal}>
  MONTAR MINHA DIETA
</button>

  </div>
) : (
  <div>
    <button className="send-button my-3" disabled={userTickets === 0} onClick={handleSubmit}>
      GERAR DIETA
    </button>
    {error && (
      <div className="chat-error text-white bg-red-600 rounded-md mb-3">
        {error}
      </div>
    )}
  </div>
)}

      <div className="chat-input">
        <label htmlFor="response">Dieta Personalizada</label>
        <textarea id="response" name="response" rows="20" value={answer} readOnly></textarea>
        {recomend && <div className="chat-error text-red-600 mt-2 mb-5">{recomend}</div>}
      </div>
      <button className="download-button" onClick={handleDownloadPDF}>
        Baixar PDF
      </button>

      {isLoading && (
        <div className="overlay">
          <div className="loading-message">{loadingMessage}</div>
        </div>
      )}
      <Modal isOpen={isBuyModalOpen} onClose={closeBuyModal} />
    </div>
  );
}

export default ChatContainer;
