import { Link, useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';

const linkStyle = {
  textDecoration: 'none',
};

const Suporte = () => {
  const navigate = useNavigate();

  const Voltar = () => {
    navigate('/home');
  };

  return (
    <div className="w-full flex flex-col items-center p-6 bg-gray-100 rounded-md shadow-md">

      <p className="text-lg text-center text-gray-700 mb-6">
        Está precisando falar conosco? Por favor, escolha um dos canais abaixo:
      </p>
      <Link to={"https://api.whatsapp.com/send?phone=5524993288136"} style={linkStyle}>
        <button className="mt-5 mx-auto text-white px-10 py-3 rounded-lg bg-green-500 hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" />
          Atendimento Whatsapp
        </button>
      </Link>

    

      <button className="mt-10 border mx-auto text-gray-800 px-10 py-3 rounded-lg hover:bg-gray-300" onClick={Voltar}>
        VOLTAR
      </button>
    </div>
  );
};

export default Suporte;
