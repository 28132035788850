import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase';
import { get, getDatabase, ref, set, update } from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';


const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const createUser = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const uid = userCredential.user.uid;
    // Crie um nó no Realtime Database para armazenar informações do usuário
    const db = getDatabase();
    const userRef = ref(db, "users/" + uid);
    set(userRef, {
      email: email,
      Tickets: 0,
      lastLogin: new Date().toISOString()
    });
    setUser(userCredential.user);
  };


  const signIn = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const uid = userCredential.user.uid;
    // Crie um nó no Realtime Database para armazenar informações do usuário
    const db = getDatabase();
    const userRef = ref(db, "users/" + uid);
    update(userRef, {
      email: email,
      lastLogin: new Date().toISOString()
    });
    setUser(userCredential.user);
  };

  const retornaTicket = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTickets = userData.Tickets;
          return userTickets; // Retorna o valor dos tickets
        } else {
          console.log("O usuário não existe no Realtime Database.");
          return null; // Retorna null se o usuário não existir
        }
      } else {
        console.log("Nenhum usuário autenticado.");
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null; // Retorna null em caso de erro
    }
  };

  const adicionarTicket = async (tipo) => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets == 0) {
            const setTicket = tipo
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
            navigate(location.pathname);
          } else {
            console.log('Você já comprou um Ticket')
          }
        }
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
    }
  };

  const retirarTicket = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets) {
            const setTicket = 0
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
            navigate(location.pathname);
          } else {
            console.log('Não possui tickets')
          }
        }
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
    }
  };


  const logout = () => {
    return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log(currentUser);
      setUser(currentUser);
      if (currentUser !== null && window.location.pathname === "/") {
        navigate('/home');
      }

    });
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn, retornaTicket, adicionarTicket, retirarTicket }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
