// HamburgerMenu.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './HamburgerMenu.module.css';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';

function HamburgerMenu({ isOpen, toggleMenu }) {
  const { user,retornaTicket } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  const navigate = useNavigate();

  const ContatoSuporte = () => {
    navigate('/suporte');
  };

  useEffect(() => {
    retornaTicket()
      .then(async (tickets) => {
        if (tickets !== null) {
          await setUserTickets(tickets);
        } else {
          setUserTickets('Nenhum ticket disponível');
        }
      })
      .catch((error) => {
        console.error('Erro ao obter os tickets:', error);
        setUserTickets('Erro ao obter os tickets');
      });
  }, [retornaTicket]);


  return (
    <div>
      <div className={styles['menu-toggle']} onClick={toggleMenu}>
        <div className={`${styles['menu-bar']} ${isOpen ? styles.open : ''}`}></div>
        <div className={`${styles['menu-bar']} ${isOpen ? styles.open : ''}`}></div>
        <div className={`${styles['menu-bar']} ${isOpen ? styles.open : ''}`}></div>
      </div>
      {isOpen && (
        <div className={`${styles['hamburger-menu']} ${isOpen ? styles.open : ''}`}>
          <div className={`${styles['close-button']}`} onClick={toggleMenu}>
            X
          </div>
          <nav>
            <ul className={`${styles.menuList} p-0`}>
              <li className={`${styles.menuItem} border-y`} onClick={ContatoSuporte}>
                Fale Conosco
              </li>
              <p className={`${styles.menuText} mt-2`}>Email: {user && user.email}</p>
              <p className={`${styles.menuText} mb-2`}>Ticket: {userTickets}</p>
             
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
}

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default HamburgerMenu;
