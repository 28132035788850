import React, { useState } from 'react';
import '../../Styles/App.css';
import axios from 'axios';
import HamburgerMenu from '../HamburguerMenu/HamburgerMenu';
import ChatContainer from '../ChatContainer/ChatContainer';
import Modal from '../Modal/Modal';

import 'bootstrap/dist/css/bootstrap.css';

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [answer, setAnswer] = useState('');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const closeBuyModal = () => {
    setIsBuyModalOpen(false);
  };

  const sendRequestToGPT = async (data) => {
    try {
      const params = {
        model: "gpt-4-1106-preview",
        max_tokens: 2000,
        messages: [
          {
            role: 'system',
            content: 'Mantenha um tom profissional de nutrcionista e seja curto na resposta, somente a dieta'
          },
          {
            role: 'user',
            content: data
          }
        ],
      };

      const result = await axios.post("https://api.openai.com/v1/chat/completions", params, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_CHATGPT_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      const answerFromAPI = result.data.choices[0].message.content;
      setAnswer(answerFromAPI);

    } catch (err) {
      console.error(err);
    }
  };


  return (
    <div className="App pb-5">
      <header className="App-header">
        <HamburgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

    </header>

      <main className="App-main">
        <div className="hero mb-5">
        </div>
        <ChatContainer sendRequestToGPT={sendRequestToGPT} answer={answer} />
      </main>
      <Modal isOpen={isBuyModalOpen} onClose={closeBuyModal} />
    </div>
  );
}

export default Home;
