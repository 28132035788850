import React from "react";
import Signin from "./components/SignIn/Signin";
import Signup from "./components/SignUp/Signup";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Emagrecer from "./components/Checkouts/Emagrecer/Emagrecer";
import DietaTreino from "./components/Checkouts/PerderBarrriga/DietaTreino";
import Home from "./components/Home/Home";
import Termos from "./components/CondicaoUso/Termos";
import Suporte from "./components/Suporte/Suporte";
import Definicao from "./components/Checkouts/JejumIntermitente/Definicao";
import DefinicaoMassa from "./components/Checkouts/GorduraLocalizada/DefinicaoMassa";
import DefinicaoM from './components/DefinicaoMuscular/DefinicaoM'
function App() {
  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/termos" element={<Termos />} />
          <Route path="/suporte" element={<Suporte />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/emagrecer"
            element={
              <ProtectedRoute>
                <Emagrecer />
              </ProtectedRoute>
            }
          />

<Route
            path="/definicaom"
            element={
              <ProtectedRoute>
                <DefinicaoM />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/dietatreino"
            element={
              <ProtectedRoute>
                <DietaTreino />
              </ProtectedRoute>
            }
          />


          <Route
            path="/definicao"
            element={
              <ProtectedRoute>
                <Definicao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/definicaomassa"
            element={
              <ProtectedRoute>
                <DefinicaoMassa />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
