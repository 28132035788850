import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';

// Importe a imagem aqui
import NutriImagem from '../../img/NUTRIINTELIGENTELOGO.png';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termoscheck, setTermosCheck] = useState(false)
  const [error, setError] = useState('');
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleCheck = () => {
    if (termoscheck) {
      setTermosCheck(false);
    } else {
      setTermosCheck(true);
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');



    try {
      if (password !== confirmPassword) {
        setError('As senhas não são iguais. Por favor, verifique novamente.');
        console.log("Senha errada")
        return;
      } else

        if (!termoscheck) {
          setError("Você deve concordar com os termos e condições para criar uma conta");
          return;
        }

        else {
          await createUser(email, password);
          navigate('/home')
        }
    } catch (e) {
      if (e.message === "Firebase: Error (auth/email-already-in-use).") {
        setError("Email já existente. Faça o login ou crie com outro email");
      }
    }
  };

  return (
    <div className="max-w-[700px] mx-auto">
      <div className="text-center">
        <img src={NutriImagem} alt="NutriImage" className="w-64 h-64 mx-auto" />
      </div>
      <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-md p-6">
        <div className="mb-3">
          <label className="block text-gray-700 font-medium">Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="border border-red-500 rounded-lg p-3 w-full"
            type="email"
            placeholder="Seu email"
            required
          />
        </div>
        <div className="mb-3">
          <label className="block text-gray-700 font-medium">Senha</label>
          <div className="relative">
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="border border-red-500 rounded-lg p-3 w-full"
              type={showPassword ? 'text' : 'password'}
              placeholder="Sua senha"
              required
            />
            <span
              className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? '👁️' : '👁️‍🗨️'}
            </span>
          </div>
        </div>
        <div className="mb-3">
          <label className="block text-gray-700 font-medium">Confirmar Senha</label>
          <input
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="border border-red-500 rounded-lg p-3 w-full"
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirme sua senha"
            required
          />

        </div>
        <div className='flex flex-col mb-3'>
          <input type="checkbox" onChange={(e) => handleCheck(e.nativeEvent)} />
          <label>
            Eu estou de acordo com os{' '}
            <Link to="/termos" target="_blank">
              Termos e Condições de uso
            </Link>{' '}
            do Peso Inteligente.
          </label>
        </div>
        {/* Se houver um erro, exiba-o aqui */}
        {error && <p className="text-red-600 mb-4">{error}</p>}
        <button style={{ backgroundColor: '#66AB48' }} className="text-white font-medium py-3 rounded-lg w-full hover:bg-red-600">
          CRIAR CONTA
        </button>
        <div className="mt-2 text-center">
          <p className='mb-3'>Já possui uma conta?</p>
          <Link to="/">
          <button style={{ backgroundColor: '#66AB48' }} className="text-white font-medium py-3 rounded-lg w-full hover:bg-red-600">
              ENTRAR COM CONTA
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Signup;
